<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small"
                  style="margin-right: 10px; width: 150px" @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="批号">
                <el-input v-model="searchForm.batchNumber" type="text" size="small" placeholder=""
                  @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item label="供货商">
                <el-input v-model="searchForm.supplier" type="text" size="small" placeholder=""
                  @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称/别名"
                  @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet"
                  :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="addTime" label="入库时间" sortable  align="center" width="160px">
            <template slot-scope="scope">
              <p>{{ scope.row.addTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="drugName" label="药品名称"  align="center"> </el-table-column>
          <el-table-column prop="drugAlias" label="别名" align="center"> </el-table-column>
          <el-table-column prop="catogoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="specification" label="规格" align="center"></el-table-column>
          <el-table-column prop="productFactory" label="生产厂家" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="supplier" label="供应商" align="center"></el-table-column>
          <el-table-column prop="batchNumber" label="批号" align="center"></el-table-column>
          <el-table-column prop="putInPrice" label="进货价(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.putInPrice.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="putInNumber" label="入库数量" align="center"></el-table-column>
          <el-table-column prop="bankName" label="总金额(元)" align="center">
            <template slot-scope="scope">
              <p>{{ (scope.row.putInPrice* scope.row.putInNumber).toFixed(2)}}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      categoryData: [],
      searchForm: {
        categoryId: 0,
        keyWord: '',
        datepicker: '',
        batchNumber: '',
        supplier: ''
      },
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
      },
      title: "余额明细",
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: "key",
          callback: val => {
            return "NO：" + val.key
          },
        },
        当前余额: "name",
      },
      fileList: [],
    }
  },
  mounted () {
    this.getList()
    this.LoadCategory()
  },
  methods: {
    LoadCategory () {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories;
          _this.categoryData.unshift({categoryId:0,categoryName:'全部'})
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData=[]
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00"
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59"
      } else {
        item.startTime = ""
        item.endTime = ""
      }
      _this.OutpatientDomain.DrugOrgInLog(item.keyWord, item.startTime, item.endTime, item.categoryId, item.batchNumber, item.supplier, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    //模板下载
    downloadTemplate () {
      let a = document.createElement("a")
      a.href = "./drug_import_template_with_branch.xls"
      a.download = "药品模板.xlsx"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async fetchData () {
      var _this = this
      return await _this.getAllData()
    },
    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve("")
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
